import Image from 'next/image';
import Scroll from '@/components/scroll';
import SectionsEnum from '@/enums/SectionsEnum';
import useLocale from '@/hooks/useLocale';
import Email from '@/public/icons/icon_mail.svg';
import Map from '@/public/icons/icon_map.svg';
import Phone from '@/public/icons/icon_phone.svg';
import {scroll} from '@/utils/config';
import styles from './footer.module.css';

const Footer: React.FC = () => {
    const locale = useLocale();

    return (
        <footer id={SectionsEnum.contact} className={styles.footer} data-testid="component-footer">
            <h3 className={styles.heading}>
                {locale.sections.contact.title}
            </h3>
            <div className={styles.wrapper}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <h4 className={styles.subheading}>
                            {locale.sections.contact.address?.title}
                        </h4>
                        <picture className={styles.image}>
                            <Map />
                        </picture>
                        <address className={styles.address}>
                            {locale.sections.contact.address?.content}
                        </address>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h4 className={styles.subheading}>
                            {locale.sections.contact.info?.title}
                        </h4>
                        <picture className={styles.image}>
                            <Phone />
                        </picture>
                        {locale.sections.contact.info?.tel}
                        <picture className={styles.image}>
                            <Email />
                        </picture>
                        {locale.sections.contact.info?.email}
                    </div>
                    <div className="col-12 col-lg-4">
                        <h4 className={styles.subheading}>
                            {locale.sections.contact.services?.title}
                        </h4>
                        <ul className={styles.list}>
                            {locale.types.map(item => (
                                <li
                                    key={item.key}
                                    className={styles.item}
                                >
                                    <Scroll
                                        {...scroll}
                                        to={item.key}
                                        offset={-100}
                                        className={styles.button}
                                    >
                                        {item.value}
                                    </Scroll>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <hr />
                <div className={styles.end}>
                    <Image
                        src="/logo/logo_white.svg"
                        width={200}
                        height={40}
                        alt="JF Woodpall"
                    />
                    <p>
                        {locale.sections.contact.end}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
